const enumToPretty = (e: string | null | undefined): string => {
  if (!e) return ''
  const [firstWord, ...rest] = e.toLowerCase().split('_')
  let str = `${firstWord.charAt(0).toUpperCase()}${firstWord.substr(1)}`
  if (rest.length > 0) {
    str += ` ${rest.join(' ')}`
  }

  return str
}

const fullNameToShort = (name: string | null | undefined): string | null | undefined =>
  name
    ?.split(' ')
    .map((v, idx) => (idx === 0 ? `${v[0]}.` : v))
    .join(' ')

const maybePluralize = (
  count: number,
  noun: string,
  suffix = 's',
  includeCount = true
): string => `${includeCount ? `${count} ` : ''}${noun}${count !== 1 ? suffix : ''}`

/**
 * Returns a string in the format of "startTime - endTime" or "startTime" or "None"
 */
const formatStartAndEndTime = (
  startTime: string | undefined,
  endTime: string | undefined
): string => {
  if (startTime && !endTime) {
    return `${startTime}`
  }

  if (startTime && endTime) {
    return `${startTime} \u2014 ${endTime}`
  }

  return 'None'
}

const cityState = (
  city: string | null | undefined,
  state: string | null | undefined
): string => {
  if (!city && !state) return '\u2014'

  return `${city}, ${state}`
}

const formatScosResponseMessages = (message: string) => {
  const newMessage = message.split(': ').slice(1).join('')

  return newMessage ? newMessage.charAt(0).toUpperCase() + newMessage.slice(1) : message
}

const capitalize = (str: string): string => {
  if (!str) return str

  return str
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(' ')
}

const isValidEmail = (value: string | null | undefined = ''): boolean => {
  if (!value) return false

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailRegex.test(value)
}

export {
  enumToPretty,
  fullNameToShort,
  maybePluralize,
  formatStartAndEndTime,
  cityState,
  formatScosResponseMessages,
  capitalize,
  isValidEmail,
}

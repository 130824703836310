import {
  GetFuzzyMatchRoutesFilterQuery,
  GetFuzzyMatchLocationsFilterQuery,
} from '~/src/components/shared/features/__generated_types__/queries.gql'

import {
  Address,
  DocumentResponse,
  GpsPoint,
  SortInput,
} from '~/__generated_types__/globalTypes'

export type ListViewColumnStyle = {
  name: string
  widthPct?: number
  className?: string
}

export type SortingProps = {
  name: string
  direction: SortInput
}

export type LocationFields = {
  address?: Address | null
  name?: string | null
  id: bigint
  timeZoneCode: string
  timeZoneName: string
}

export type UnionStopEstimate = {
  estimatedArrivalTime?: string | null | undefined
  estimatedDepartureTime?: string | null | undefined
  estimatedDwellTime?: number | null | undefined
  milesFromPrevStop?: number | null | undefined
  estimatedMilesFromPrevStop?: number | null | undefined
  timeFromPrevStop?: number | null | undefined
  estimatedTimeFromPrevStop?: number | null | undefined
}

export type UnionStop = {
  appointmentEnd?: string | null
  appointmentStart?: string | null
  isAppointmentValid?: boolean
  arrivalTime?: string | null
  departureTime?: string | null
  documents: DocumentResponse
  estimateData?: UnionStopEstimate | null
  globalIdx?: number
  id: number
  idx: number
  location?: LocationFields | null
}

export type GeoRoutes = Array<{
  expectedRoute: Array<GpsPoint>
  actualRoute: Array<GpsPoint>
}>

export type ColoredStopMarker = {
  color: {
    twColor: string
    hexColor: string
  }
  stop: UnionStop
}

export type GPSPoint = { lat: number; lng: number }

export type Polygon = {
  id?: number
  bounds: Array<GPSPoint>
}

export type GeoAddress = {
  line1: string
  line2?: string | null
  city: string
  postalCode: string
  countryCode: string
  stateOrProvinceAbbrev: string
}

export type Location = {
  id: bigint
  name: string
  note?: string
  gpsPoint: GPSPoint
  radius: number
  gpsPolygons: Array<Polygon>
  address?: GeoAddress
}

export type GeoRoute = {
  expectedRoute: Array<GpsPoint>
  actualRoute: Array<GpsPoint>
}

export type Route = GetFuzzyMatchRoutesFilterQuery['getRoutes']['results'][0]
export type StopLocation =
  GetFuzzyMatchLocationsFilterQuery['getLocations']['results'][0]

export enum UserType {
  CUSTOMER = 'customer',
  OPERATOR = 'operator',
}
